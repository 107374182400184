import { set, omit, isEmpty, merge, get } from 'lodash';
import { getCurrentUser, signOut } from 'site-modules/shared/components/profile/firebase-auth';
import { signInAnonymously } from 'site-modules/shared/components/profile/firebase-anonymous-auth';
import { idmAPI, idmVanillaForumAPI } from 'client/data/api/api-client';
import { splitUserName } from 'site-modules/shared/utils/personal-info';
import { HTTP_NOT_FOUND } from 'client/utils/http-status';
import { PROVIDERS_MAPPING } from 'site-modules/shared/components/profile/auth-providers';
import { IDM_METHODS } from 'client/data/models/profile/profile-constants';
import {
  setAnalyticsData,
  setAnalyticsDataForSignIn,
  populateLastLandingPageVisitTs,
  populateSyncAttributes,
} from './idm-analytics';
import { getSyncDate, getSavableData } from './idm-utils';

const NO_CACHE_HEADERS = { Pragma: 'no-cache', 'Cache-Control': 'no-cache' };
const UPS_UPDATE_INTELLIGENT_MESSAGING_OMITS = ['prospectCreateTs', 'prospectEntryPoint', 'prospectEntryPointDetail'];
const SHOULD_POPULATE_PROSPECT_ENTRY = true;

/**
 * Get request options for all IDM requests
 * @param {Object} [body] - request body.
 * @param {String} [method] - request method.
 * @returns {Object} promise.
 */
export const getReqOptions = (body, method = 'GET', aid) => {
  const headers = {
    ...(method === 'GET' ? NO_CACHE_HEADERS : {}),
    ...(method === 'PUT' && aid ? { 'X-Aid': aid } : {}),
  };
  return getCurrentUser()
    .then(currentUser => currentUser && currentUser.getIdToken())
    .then(token => ({ headers: { ...headers, authorization: token }, method, body: JSON.stringify(body) }));
};

/**
 * Set username from first (recommended by firebase team) provider.
 * @param {Array} providers - array of firebase providers.
 * @param {Object} profile - destination object with user profile data.
 * @returns {Object} userProfile.
 */
export const setUserName = (providers, profile) => {
  const name = splitUserName(providers[0].displayName);

  if (name.first) {
    set(profile, 'person.firstName', name.first);
  }
  if (name.last) {
    set(profile, 'pii.lastName', name.last);
  }

  return profile;
};

/**
 * Set identifiers data (google or facebook UID, email) for IDM.
 * @param {Array} providers - firebase providers.
 * @param {Object} profile - destination object with user profile data.
 * @returns {Object} userProfile.
 */
export const setProviderData = (providers, profile) => {
  const userProfile = profile;
  if (providers) {
    providers.forEach(provider => {
      userProfile.identifiers[PROVIDERS_MAPPING[provider.providerId]] = [provider.uid];
    });
    if (!userProfile.identifiers.email) {
      userProfile.identifiers.email = [providers[0].email];
    }
    setUserName(providers, userProfile);
  }
  return userProfile;
};

/**
 * Common wrapper for IDM PUT method. Omits prospect details because this is an update call.
 * @param {Object} data - source object with user profile data.
 * @param {String} aid - anonymous id
 * @returns {Object} promise.
 */
export const update = (data, aid) => {
  const userData = data;
  if (!isEmpty(userData.intelligentMessaging)) {
    userData.intelligentMessaging = omit(userData.intelligentMessaging, UPS_UPDATE_INTELLIGENT_MESSAGING_OMITS);
  }
  return getReqOptions(userData, 'PUT', aid).then(options => idmAPI.fetchJson('/delta?isTransactional=true', options));
};

/**
 * Common wrapper for IDM PUT method (Only for Sign Up and Social Sign in)
 * @param {Object} data - source object with user profile data.
 * @param {String} aid - anonymous id
 * @returns {Object} promise.
 */
export const create = (data, aid) =>
  getReqOptions(data, 'PUT', aid).then(options => idmAPI.fetchJson('/?isTransactional=true', options));

/**
 * Common wrapper for IDM DELETE method (Only for Sign Up and Social Sign in)
 * @param {Object} data - source object with user profile data.
 * @returns {Object} promise.
 */
export const remove = () => getReqOptions(undefined, 'DELETE').then(options => idmAPI.fetchJson('/', options));

/**
 * Gets user profile from IDM
 * @returns {Object} promise.
 */
export const getProfile = () =>
  getReqOptions()
    .then(options => idmAPI.fetchJson('', options))
    .then(data => data.result);

/**
 * Generates vanilla_sso signature string for vanilla forums authentication
 * @returns {String} vanilla_sso signature string
 */
export const getVanillaSSOSignatureString = () =>
  getReqOptions()
    .then(options => idmVanillaForumAPI.fetchJson('/authtoken', options))
    .then(({ result: { ssoToken } }) => ssoToken);

/**
 * Common wrapper for IDM PUT method (only for Sign Up & Social Sign In)
 * Sends prospectCreateTs only if the user profile was not found in IDM (if IDM returns 404)
 * @param {Object} profile - source object with user profile data.
 * @param {String} uid
 * @returns {Object} promise.
 */
export const save = (profile, uid) => {
  const userData = profile;
  return getProfile()
    .catch(({ status }) => {
      const is404error = status === HTTP_NOT_FOUND;
      if (is404error) {
        userData.intelligentMessaging.prospectCreateTs = getSyncDate();
      }
      return is404error;
    })
    .then(profileData => {
      const isNewProfile = isEmpty(profileData);
      if (isNewProfile) {
        userData.intelligentMessaging.prospectCreateTs = getSyncDate();
      }

      return (isNewProfile ? create : update)(userData, uid);
    });
};

export function updateLastLandingTs() {
  return update(populateLastLandingPageVisitTs());
}

/**
 * Creates user profile in IDM (only for Sign Up & Social Sign In)
 * Sends prospectCreateTs only if the user profile was not found in IDM
 * @param {Object} data - source object with user personal info (firstName, lastName, email).
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @param {String} uid
 * @returns {Object} promise.
 */
export function createProfile({ data = {}, visitorAndPageContext, uid }) {
  return getCurrentUser().then(user => {
    const profile = {
      identifiers: {},
      recordType: 'Aggregate',
    };
    // Social sign in, google-one-tap case personalInfo.providerData
    const providerData = get(user, 'providerData.length') ? user.providerData : data.providerData;
    setProviderData(providerData, profile);

    setAnalyticsDataForSignIn(profile, visitorAndPageContext, SHOULD_POPULATE_PROSPECT_ENTRY);
    merge(profile, getSavableData(data));

    return save(profile, uid);
  });
}

/**
 * Save or delete user data in IDM (lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @param {String} [method] - supported methods: UPDATE, DELETE.
 * @returns {Object} promise.
 */
export function updateProfileData({ data = {}, visitorAndPageContext, method }) {
  const userData = data;
  userData.recordType = method === IDM_METHODS.DELETE ? 'Delete' : 'Upsert';

  if (method !== IDM_METHODS.DELETE) {
    setAnalyticsData(userData, visitorAndPageContext);
  } else {
    merge(userData, populateSyncAttributes());
  }

  return update(userData);
}

/**
 * Updates signed in users data in IDM(lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @param {String} uid
 * @returns {Object} promise.
 */
export function updateLoginProfile({ data = {}, visitorAndPageContext, uid } = {}) {
  const userData = data;
  userData.recordType = 'Upsert';
  setAnalyticsDataForSignIn(userData, visitorAndPageContext);

  return update(userData, uid);
}

/**
 * Creates anonymous user with data (lastLoginTs, Vins etc)
 * @param {Object} data - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export function createAnonymousProfile({ data = {}, visitorAndPageContext } = {}) {
  const userData = data;
  userData.recordType = 'Upsert';
  setAnalyticsDataForSignIn(userData, visitorAndPageContext, SHOULD_POPULATE_PROSPECT_ENTRY);

  return create(userData);
}

/**
 * Signs out of current profile, signs in and creates anonymous user
 * @param {Object} data - Data to save for new anonymous user, sign in anonymously tracking data.
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export function signInAndCreateAnonymousProfile({
  data: { dataToCreate, signInAnonymouslyTracking },
  visitorAndPageContext,
}) {
  return signOut()
    .then(() => signInAnonymously(signInAnonymouslyTracking.creativeId, signInAnonymouslyTracking.trackingData))
    .then(() => createAnonymousProfile({ data: dataToCreate, visitorAndPageContext }));
}

/**
 * Updates to currentta - data to update for current user, data to save for new anonymous user, sign in anonymously tracking data.
 * @param {Object} visitorAndPageContext - visitor and page context data for analytics.
 * @returns {Object} profile, signs out of current profile, signs in and creates anonymous user
 * @param {Object} da promise.
 */
export function updateToCurrentThenSignInAndCreateAnonymousProfile({
  data: { dataToUpdate, dataToCreate, signInAnonymouslyTracking },
  visitorAndPageContext,
}) {
  return updateProfileData({ data: dataToUpdate, visitorAndPageContext, method: IDM_METHODS.UPDATE })
    .then(() => signOut())
    .then(() => signInAnonymously(signInAnonymouslyTracking.creativeId, signInAnonymouslyTracking.trackingData))
    .then(() => createAnonymousProfile({ data: dataToCreate, visitorAndPageContext }));
}

/**
 * Save or user data in IDM in one request
 * @param {Object} deleteData - data to delete.
 * @param {Object} updateData - data to save.
 * @param {Object} [visitorAndPageContext] - visitor and page context data for analytics.
 * @returns {Object} promise.
 */
export function mixedUpdateProfileData({ data: { deleteData, updateData }, visitorAndPageContext }) {
  return Promise.all([
    deleteData && updateProfileData({ data: deleteData, method: IDM_METHODS.DELETE }),
    updateData && updateProfileData({ data: updateData, visitorAndPageContext, method: IDM_METHODS.UPDATE }),
  ]);
}
