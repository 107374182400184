import {
  createProfile,
  updateProfileData,
  mixedUpdateProfileData,
  updateLastLandingTs,
  updateLoginProfile,
  createAnonymousProfile,
  updateToCurrentThenSignInAndCreateAnonymousProfile,
  signInAndCreateAnonymousProfile,
} from 'site-modules/shared/components/profile/idm/idm';

export const PROFILE_CONSTANTS = {
  APPRAISALS_PER_PAGE: 3,
  EMPTY_DATA: [],
};

export const IDM_METHODS = {
  CLEAR: 'CLEAR',
  CREATE: 'CREATE',
  CREATE_ANONYMOUS: 'CREATE_ANONYMOUS',
  DELETE: 'DELETE',
  LOAD: 'LOAD',
  MIXED_UPDATE: 'MIXED_UPDATE',
  SIGN_IN_CREATE_ANONYMOUS: 'SIGN_IN_CREATE_ANONYMOUS',
  UPDATE: 'UPDATE',
  UPDATE_CURRENT_SIGN_IN_CREATE_ANONYMOUS: 'UPDATE_CURRENT_SIGN_IN_CREATE_ANONYMOUS',
  UPDATE_LOGIN: 'UPDATE_LOGIN',
};

export const IDM_METHODS_MAPPING = {
  [IDM_METHODS.CREATE]: createProfile,
  [IDM_METHODS.UPDATE]: updateProfileData,
  [IDM_METHODS.UPDATE_LOGIN]: updateLoginProfile,
  [IDM_METHODS.CREATE_ANONYMOUS]: createAnonymousProfile,
  [IDM_METHODS.DELETE]: updateProfileData,
  [IDM_METHODS.LOAD]: updateLastLandingTs,
  [IDM_METHODS.MIXED_UPDATE]: mixedUpdateProfileData,
  [IDM_METHODS.UPDATE_CURRENT_SIGN_IN_CREATE_ANONYMOUS]: updateToCurrentThenSignInAndCreateAnonymousProfile,
  [IDM_METHODS.SIGN_IN_CREATE_ANONYMOUS]: signInAndCreateAnonymousProfile,
  [IDM_METHODS.CLEAR]: () => Promise.resolve(undefined),
};
