import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, merge, noop, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { UserProfileModel } from 'client/data/models/profile/profile';
import { signInAnonymously } from 'site-modules/shared/components/profile/firebase-anonymous-auth';
import {
  getFinancingAttributes,
  setFinancingAttributes,
} from 'client/site-modules/shared/components/profile/attributes/bundles';
import { connectToModel } from 'client/data/luckdragon/redux/react-binding';
import { Storage } from 'client/site-modules/shared/utils/storage';
import { profileScreenMethods } from 'site-modules/shared/components/profile/profile-screen-methods';
import { IDM_METHODS } from 'client/data/models/profile/profile-constants';

const AUTHORIZATION_DISABLED_STYLES = { opacity: '0.5', pointerEvents: 'none' };
const storage = new Storage('localStorage');
const mergeFinancingIdmData = idmData => merge(idmData, getFinancingAttributes(storage));
const empty = {};
export const mapStateToProps = state => ({
  visitor: state.visitor,
  pageContext: state.pageContext,
  isAuthenticated: get(state, 'profile.isAuthenticated'),
  modelLinkCode: get(state, 'pageContext.vehicle.model.modelLinkCode'),
  dataIdm: get(state, 'profile.data.idm', empty),
});

/**
 * insiderMethods: HOC to pass common Idm methods into child components
 * @param component React Element requiring Idm methods
 * @param {Object} options
 *  @param {Boolean} waitForAuth - Collapse wrapped component until authenticated
 *  @param {Boolean} includeIdmData - Adds `profileIdmData` to props so wrapped component has access to data
 * @returns React Element function to render WrappedComponent with Idm functions and passed in props
 */
export function insiderMethods(component, options = {}) {
  /**
   * InsiderMethodsWrapper: Wrapper to provide connectToModel methods used by Idm methods
   * @param WrappedComponent React Element requiring Idm methods
   * @param wrappedProps Props to be passed into WrappedComponent
   * @param setModelValue Method provided by connectToModel used by Idm methods
   * @returns React Element rendering WrappedComponent with Idm functions and passed in props
   */
  class InsiderMethodsWrapper extends Component {
    static propTypes = {
      WrappedComponent: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string,
        PropTypes.func,
        PropTypes.elementType,
      ]).isRequired,
      setModelValue: PropTypes.func.isRequired,
      toggleSignModal: PropTypes.func.isRequired,
      wrappedProps: PropTypes.shape({}),
      pageContext: PropTypes.shape({
        page: PropTypes.shape({}),
      }),
      visitor: PropTypes.shape({
        id: PropTypes.string,
        session: PropTypes.string,
      }),
      isAuthenticated: PropTypes.bool,
      modelLinkCode: PropTypes.string,
      dataIdm: PropTypes.shape({}),
    };

    static defaultProps = {
      wrappedProps: {},
      pageContext: {},
      visitor: {},
      isAuthenticated: null,
      modelLinkCode: '',
      dataIdm: {},
    };

    componentDidUpdate(prevProps) {
      const { dataIdm, setModelValue } = this.props;
      const { financing } = dataIdm;
      const shouldSetFinanceData = isEmpty(prevProps.dataIdm.financing) && !isEmpty(financing);
      if (shouldSetFinanceData) setFinancingAttributes(storage, dataIdm, setModelValue);
    }

    updateModel(data, method, addVisitorAndPageContext, uid) {
      const { setModelValue, visitor, pageContext } = this.props;
      return setModelValue('data.idm', UserProfileModel, {
        data,
        visitorAndPageContext: addVisitorAndPageContext
          ? {
              visitor,
              pageContext,
            }
          : undefined,
        method,
        uid,
      });
    }

    /**
     * removeDataFromIdm - Function will also update UPS with any local storage calculator preferences (MEM-3126)
     * @param {Object} idmData - idmData to delete
     */
    removeDataFromIdm = idmData =>
      Promise.all([
        this.updateModel(mergeFinancingIdmData(), IDM_METHODS.UPDATE),
        this.updateModel(idmData, IDM_METHODS.DELETE),
      ]);

    /**
     * updateIdmData - Saves "idmData" to UPS.
     * @param {Object} idmData - idmData to to save
     */
    updateIdmData = idmData => this.updateModel(mergeFinancingIdmData(idmData), IDM_METHODS.UPDATE);

    /**
     * updateIdmDataWithContext - Saves "idmData" with location and visitor context to UPS.
     * @param {Object} idmData - idmData to to save
     */
    updateIdmDataWithContext = idmData => this.updateModel(mergeFinancingIdmData(idmData), IDM_METHODS.UPDATE, true);

    /**
     * updateLoginIdmData - Saves to "idmData" with login attributes to UPS.
     * @param {Object} idmData - idmData to save
     * @param {String} uid - Anonymous id, if exists
     */
    updateLoginIdmData = (idmData, uid) => this.updateModel(idmData, IDM_METHODS.UPDATE_LOGIN, true, uid);

    /**
     * createAnonymousIdmProfile - Saves "idmData" to UPS for new anonymous user. If possible please use "signInAnonymousAndSaveProfile" method below.
     * IMPORTANT: This should ONLY be used for newly "signInAnonymously" users (so it should happen right after firebase sign in anonymously).
     * @param {Object} idmData - idmData to to save
     */
    createAnonymousIdmProfile = idmData =>
      this.updateModel(mergeFinancingIdmData(idmData), IDM_METHODS.CREATE_ANONYMOUS, true);

    /**
     * @function updateToCurrentThenSignInAnonymousAndSave
     * Process:
     * 1. Updates to current profile
     * 2. Performs sign out of current profile
     * 3. Performs sign in to anonymous user (new) and saves data.
     * @param {Object} dataToUpdate - idmData to update for current user
     * @param {Object} dataToCreate - idmData to save for new anonymous user
     * @param {Object} signInAnonymouslyTracking - tracking data for `signInAnonymously`.
     */
    updateToCurrentThenSignInAnonymousAndSave = (dataToUpdate, dataToCreate, signInAnonymouslyTracking) =>
      this.updateModel(
        {
          dataToUpdate,
          dataToCreate: mergeFinancingIdmData(dataToCreate),
          signInAnonymouslyTracking,
        },
        IDM_METHODS.UPDATE_CURRENT_SIGN_IN_CREATE_ANONYMOUS,
        true
      );

    /**
     * signInAnonymousAndSaveProfile - Signs user in anonymously through firebase and save "idmData" to UPS right after.
     * @param {Object} idmData - idmData to to save
     * @param {Object} additionalOptions
     *    @param {Boolean} isNewAnonymousUser - Determines whether to sign out of current profile when user is authenticated.
     *    @param {Object} signInAnonymouslyTracking
     *      @param {String} creativeId - creative id for sign in anonymously tracking purposes
     *      @param {Object} trackingData - additional tracking data for sign in anonymous tracking purposes
     */
    signInAnonymousAndSaveProfile = (idmData, additionalOptions = {}) => {
      const { signInAnonymouslyTracking = {}, isNewAnonymousUser = false } = additionalOptions;
      const { isAuthenticated } = this.props;

      if (isNewAnonymousUser && isAuthenticated) {
        return this.updateModel(
          {
            dataToCreate: mergeFinancingIdmData(idmData),
            signInAnonymouslyTracking,
          },
          IDM_METHODS.SIGN_IN_CREATE_ANONYMOUS,
          true
        );
      }

      const { creativeId = '', trackingData = {} } = signInAnonymouslyTracking;
      return signInAnonymously(creativeId, trackingData).then(() => this.createAnonymousIdmProfile(idmData));
    };

    /**
     * signInAnonymousWithToggleModalAndSave - Signs user in anonymously through firebase then determines whether to show modal or not before saving data.
     * If user is already authenticated then it WILL NOT sign in anonymously.
     * @param {Object} idmData - idmData to to save
     * @param {Object} additionalData
     *    @param {String} creativeId - creative id for sign in anonymously tracking purposes
     *    @param {Object} trackingData - additional tracking data for sign in anonymous tracking purposes
     *    @param {Boolean} showModal - to determine whether show modal or not
     */
    signInAnonymousWithToggleModalAndSave = (idmData, additionalData = {}) => {
      const { isAuthenticated, toggleSignModal } = this.props;
      const { creativeId = '', trackingData = {}, showModal = false } = additionalData;
      const toggleModal = Promise.resolve(showModal ? toggleSignModal({ idmDataToSave: idmData }) : noop());

      if (isAuthenticated) {
        return toggleModal.then(() => this.updateIdmDataWithContext(idmData));
      }

      return signInAnonymously(creativeId, trackingData)
        .then(toggleModal)
        .then(() => this.createAnonymousIdmProfile(idmData));
    };

    /**
     * createIdmData - Saves "idmData" to UPS for new REGISTERED USER.
     * @param {Object} idmData - idmData to save
     * @param {String} uid - Anonymous id, if exists
     */
    createIdmData = (idmData, uid) => this.updateModel(idmData, IDM_METHODS.CREATE, true, uid);

    render() {
      const { WrappedComponent, wrappedProps, isAuthenticated, modelLinkCode, dataIdm } = this.props;
      const { waitForAuth, includeIdmData } = options;
      const additionalProps = {};
      if (includeIdmData) {
        additionalProps.profileIdmData = dataIdm;
      }
      const componentWithProps = (
        <WrappedComponent
          removeDataFromIdm={this.removeDataFromIdm}
          updateIdmData={this.updateIdmData}
          updateIdmDataWithContext={this.updateIdmDataWithContext}
          updateLoginIdmData={this.updateLoginIdmData}
          createAnonymousIdmProfile={this.createAnonymousIdmProfile}
          signInAnonymousAndSaveProfile={this.signInAnonymousAndSaveProfile}
          signInAnonymousWithToggleModalAndSave={this.signInAnonymousWithToggleModalAndSave}
          updateToCurrentThenSignInAnonymousAndSave={this.updateToCurrentThenSignInAnonymousAndSave}
          createIdmData={this.createIdmData}
          {...wrappedProps}
          isAuthenticated={isAuthenticated}
          modelLinkCode={modelLinkCode}
          {...additionalProps}
        />
      );

      if (waitForAuth && isAuthenticated === null) {
        return (
          <span className="authorization-disabled" style={AUTHORIZATION_DISABLED_STYLES}>
            {componentWithProps}
          </span>
        );
      }

      return componentWithProps;
    }
  }

  const ConnectedComponentWithContext = connect(mapStateToProps)(
    connectToModel(profileScreenMethods(InsiderMethodsWrapper), {})
  );

  return props => <ConnectedComponentWithContext WrappedComponent={component} wrappedProps={props} />;
}
